import React from 'react';
import BaseSlider from './BaseSlider';

export default function RtlSlider({ title, info, img, id }) {
	return (
		<article className="row light-gray-bg px-2 px-lg-5 px-md-4 px-sm-2 py-3 py-lg-5 py-md-3 py-sm-3 my-4">
			<div className="col-12 col-lg-7 col-md-12 col-sm-7">
				<BaseSlider img={img} title={title} carouselId={`carousel${id}`} />
			</div>
			<div className="col-12 col-lg-5 col-md-12 col-sm-12">
				<h2 className="cu-font-thin light-blue mt-3">{title}</h2>

				<p className="mt-4">
					<ul>
						{info.map((item) => (
							<li className="font-size-1-1 my-2">{item.description}</li>
						))}
					</ul>
				</p>
			</div>
		</article>
	);
}
