import React from 'react';

export default function Header() {
	return (
		<div className="m-5 light-blue">
			<h1 className="d-flex justify-content-center align-items-center flex-column">
				<div className="cu-font-thin font-size-2-1 mb-4">
					Villa Frangipani Canggu
				</div>
				<div className="cu-font-thin font-size-2-1 mb-3">Guest Reviews</div>
			</h1>
		</div>
	);
}
