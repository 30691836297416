import React, { useState } from 'react';
import Navbar from '../../../components/navbar/Navbar';
import Footer from '../../../components/footer/Footer';
import CopyRight from '../../../components/copyright/CopyRight';
import PageMenu from './PageMenu';
import OurMenuResource from '../../../resources/OurMenuResources';
import MenuItems from './MenuItems';
import { useSEOTags } from '../../../hooks';
import { TITLES_DESCRIPTIONS } from '../../../constants';

export default function Menu() {
	const [activePage, setActivePage] = useState(1);

	useSEOTags(
		TITLES_DESCRIPTIONS.menu.title,
		TITLES_DESCRIPTIONS.menu.description,
	);

	return (
		<div className="bg-light-golden">
			<Navbar />
			<PageMenu
				resource={OurMenuResource[0]}
				activeMenu={activePage}
				setActiveMenu={setActivePage}
			/>
			<MenuItems resource={OurMenuResource[activePage]} />
			<Footer />
			<CopyRight />
		</div>
	);
}
