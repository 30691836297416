/**
 * @param {string} path - resource's path
 * @returns {string} url of the resource using current location origin
 */
export function resolvePathOrigin(path) {
	if (typeof window === 'undefined') {
		return path;
	}

	return `${window.location.origin}${path}`;
}
