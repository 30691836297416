const MapItemResources = [
	{
		id: 1,
		title: 'Pererenan Beach',
		text: '2 km',
	},
	{
		id: 2,
		title: 'Echo Beach',
		text: '5 km',
	},
	{
		id: 3,
		title: 'Batu Belig Beach',
		text: '6 km',
	},
	{
		id: 4,
		title: 'Tanah Lot',
		text: '8 km',
	},
	{
		id: 5,
		title: 'Seminyak',
		text: '11 km',
	},
	{
		id: 6,
		title: 'Kuta',
		text: '15 km',
	},
	{
		id: 7,
		title: 'Ngurah Rai Airport',
		text: '19 km',
	},
	{
		id: 8,
		title: 'Ubud',
		text: '27 km',
	},
];
export default MapItemResources;
