const countries = [
	{ name: 'Singapore', code: 'SG', phoneCode: '+65' },
	{ name: 'Afghanistan', code: 'AF', phoneCode: '+93' },
	{ name: 'Albania', code: 'AL', phoneCode: '+355' },
	{ name: 'Algeria', code: 'DZ', phoneCode: '+213' },
	{ name: 'Argentina', code: 'AR', phoneCode: '+54' },
	{ name: 'Australia', code: 'AU', phoneCode: '+61' },
	{ name: 'Austria', code: 'AT', phoneCode: '+43' },
	{ name: 'Bahrain', code: 'BH', phoneCode: '+973' },
	{ name: 'Bangladesh', code: 'BD', phoneCode: '+880' },
	{ name: 'Belgium', code: 'BE', phoneCode: '+32' },
	{ name: 'Benin', code: 'BJ', phoneCode: '+229' },
	{ name: 'Bolivia', code: 'BO', phoneCode: '+591' },
	{ name: 'Brazil', code: 'BR', phoneCode: '+55' },
	{ name: 'Bulgaria', code: 'BG', phoneCode: '+359' },
	{ name: 'Cambodia', code: 'KH', phoneCode: '+855' },
	{ name: 'Cameroon', code: 'CM', phoneCode: '+237' },
	{ name: 'Canada', code: 'CA', phoneCode: '+1' },
	{ name: 'Chad', code: 'TD', phoneCode: '+235' },
	{ name: 'Chile', code: 'CL', phoneCode: '+56' },
	{ name: 'China', code: 'CN', phoneCode: '+86' },
	{ name: 'Colombia', code: 'CO', phoneCode: '+57' },
	{ name: 'Costa Rica', code: 'CR', phoneCode: '+506' },
	{ name: 'Croatia', code: 'HR', phoneCode: '+385' },
	{ name: 'Cuba', code: 'CU', phoneCode: '+53' },
	{ name: 'Cyprus', code: 'CY', phoneCode: '+357' },
	{ name: 'Czech Republic', code: 'CZ', phoneCode: '+420' },
	{ name: 'Democratic Republic of the Congo', code: 'CD', phoneCode: '+243' },
	{ name: 'Denmark', code: 'DK', phoneCode: '+45' },
	{
		name: 'Dominican Republic',
		code: 'DO',
		phoneCode: '+1-809, +1-829, +1-849',
	},
	{ name: 'Ecuador', code: 'EC', phoneCode: '+593' },
	{ name: 'Egypt', code: 'EG', phoneCode: '+20' },
	{ name: 'El Salvador', code: 'SV', phoneCode: '+503' },
	{ name: 'Estonia', code: 'EE', phoneCode: '+372' },
	{ name: 'Ethiopia', code: 'ET', phoneCode: '+251' },
	{ name: 'Finland', code: 'FI', phoneCode: '+358' },
	{ name: 'France', code: 'FR', phoneCode: '+33' },
	{ name: 'Gabon', code: 'GA', phoneCode: '+241' },
	{ name: 'Germany', code: 'DE', phoneCode: '+49' },
	{ name: 'Ghana', code: 'GH', phoneCode: '+233' },
	{ name: 'Greece', code: 'GR', phoneCode: '+30' },
	{ name: 'Guatemala', code: 'GT', phoneCode: '+502' },
	{ name: 'Honduras', code: 'HN', phoneCode: '+504' },
	{ name: 'Hungary', code: 'HU', phoneCode: '+36' },
	{ name: 'Iceland', code: 'IS', phoneCode: '+354' },
	{ name: 'India', code: 'IN', phoneCode: '+91' },
	{ name: 'Indonesia', code: 'ID', phoneCode: '+62' },
	{ name: 'Iran', code: 'IR', phoneCode: '+98' },
	{ name: 'Iraq', code: 'IQ', phoneCode: '+964' },
	{ name: 'Ireland', code: 'IE', phoneCode: '+353' },
	{ name: 'Israel', code: 'IL', phoneCode: '+972' },
	{ name: 'Italy', code: 'IT', phoneCode: '+39' },
	{ name: 'Ivory Coast', code: 'CI', phoneCode: '+225' },
	{ name: 'Jamaica', code: 'JM', phoneCode: '+1-876' },
	{ name: 'Japan', code: 'JP', phoneCode: '+81' },
	{ name: 'Jordan', code: 'JO', phoneCode: '+962' },
	{ name: 'Kazakhstan', code: 'KZ', phoneCode: '+7' },
	{ name: 'Kenya', code: 'KE', phoneCode: '+254' },
	{ name: 'Kuwait', code: 'KW', phoneCode: '+965' },
	{ name: 'Kyrgyzstan', code: 'KG', phoneCode: '+996' },
	{ name: 'Lebanon', code: 'LB', phoneCode: '+961' },
	{ name: 'Libya', code: 'LY', phoneCode: '+218' },
	{ name: 'Madagascar', code: 'MG', phoneCode: '+261' },
	{ name: 'Malaysia', code: 'MY', phoneCode: '+60' },
	{ name: 'Mali', code: 'ML', phoneCode: '+223' },
	{ name: 'Mexico', code: 'MX', phoneCode: '+52' },
	{ name: 'Mongolia', code: 'MN', phoneCode: '+976' },
	{ name: 'Morocco', code: 'MA', phoneCode: '+212' },
	{ name: 'Mozambique', code: 'MZ', phoneCode: '+258' },
	{ name: 'Myanmar', code: 'MM', phoneCode: '+95' },
	{ name: 'Nepal', code: 'NP', phoneCode: '+977' },
	{ name: 'Netherlands', code: 'NL', phoneCode: '+31' },
	{ name: 'New Zealand', code: 'NZ', phoneCode: '+64' },
	{ name: 'Nicaragua', code: 'NI', phoneCode: '+505' },
	{ name: 'Niger', code: 'NE', phoneCode: '+227' },
];

export { countries };
