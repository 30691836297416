import React from 'react';
import CopyRight from '../../../components/copyright/CopyRight';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import GallerySection from './GallerySection';
import { useSEOTags } from '../../../hooks';
import { TITLES_DESCRIPTIONS } from '../../../constants';

export default function Gallery() {
	useSEOTags(
		TITLES_DESCRIPTIONS.gallery.title,
		TITLES_DESCRIPTIONS.gallery.description,
	);

	return (
		<div className="light-gray-bg">
			<Navbar />

			<div className="px-5 mx-2 mt-4 text-center">
				<h1 className="cu-font-thin light-blue font-size-2-1">Gallery</h1>
			</div>

			<div className="my-4">
				<GallerySection />
			</div>
			<Footer />
			<CopyRight />
		</div>
	);
}
