import React from 'react';

export default function MapImg() {
	return (
		<div className="d-flex justify-content-center align-items-center my-5 mx-2 mx-lg-5 mx-md-3 mx-sm-2">
			<img
				className="w-100"
				alt="map img"
				src="/assets/img/theVilla/floorPlan/villa_frangipani_canggu_floorplan.webp"
			/>
		</div>
	);
}
