import React from 'react';
import NeighbourhoodSectionResources from '../../../resources/neighbourhoodSection';

export default function NeighbourhoodSection() {
	return (
		<div className="mx-5">
			{NeighbourhoodSectionResources.map((item) => (
				<div key={item.id} className="my-5">
					<h2 className="cu-font-thin light-blue h4 mb-5">{item.category}</h2>

					<p className="font-size-1-1">{item.categoryDetails}</p>

					<ul className="list-unstyled">
						{item.Info.map((info) => (
							<li key={info.id} className="my-4">
								<span className="light-blue font-size-1-1">{info.name}</span>
								<p className="font-size-1-1 d-inline"> - {info.text}</p>
								<a href={info.linkHref} className="light-blue fs-5">
									{info.linkName}
								</a>
							</li>
						))}
					</ul>
				</div>
			))}
		</div>
	);
}
