const NeighbourhoodSectionResources = [
	{
		id: 1,
		category: 'Restaurants',
		categoryDetails:
			'A stones throw from Villa Frangipani can be found many different cuisines.  We list below some of our favourites (also marked in the Map section of this website), and recommend that bookings are made particularly when dining out in the evening.',
		Info: [
			{
				id: 1,
				name: 'Shelter',
				text: 'modern Middle Eastern and Meditteranean food in a garden joglo setting.',
				linkName: 'shelterbali',
				linkHref: 'https://www.shelterbali.com/',
			},
			{
				id: 2,
				name: 'Home by Chef Wayan',
				text: 'a casual restaurant, serving delicious modern Asian cuisine.',
				linkName: 'home-by-chef-wayan',
				linkHref: 'https://home-by-chef-wayan.business.site/',
			},
			{
				id: 3,
				name: 'Warung Yess',
				text: 'step into a Balinese home compound, converted to a simple casual restaurant serving home cooked Balinese nasi campur.  pick the dishes you want to be served with white rice.',
				linkName: 'warung yess',
				linkHref: 'https://www.facebook.com/warungyess',
			},
			{
				id: 4,
				name: 'Riviera Bistro',
				text: ' A Seafood Bistro & Wine Bar Featuring Tastes Of The French Riviera, Impeccable Crafted Cocktails, A Curated Wine List, And An Unforgettable Atmosphere.',
				linkName: 'rivierabistroid',
				linkHref: 'https://rivierabistroid.com/',
			},
			{
				id: 5,
				name: 'Leblon',
				text: 'Brazilian Aperitivo Lounge concept, Leblon serves up delicious shared tapas and fine wine, complemented by its beautifully presented signature cocktails.',
				linkName: 'Leblon',
				linkHref: 'https://www.instagram.com/lebl...',
			},
			{
				id: 6,
				name: 'Zali',
				text: 'authentic Lebanese home cooking.',
				linkName: 'zalifrombeirut',
				linkHref: 'https://www.zalifrombeirut.com/',
			},
			{
				id: 7,
				name: 'Manas 2 cafe',
				text: 'excellent cooked breakfasts, fresh fruit juices, large yoghurt bowls.',
				linkName: 'Manas 2 cafe',
				linkHref:
					'https://www.instagram.com/manascafebali/?igshid=YmMyMTA2M2Y%3D',
			},
		],
	},
	{
		id: 2,
		category: 'Shops and Services',
		categoryDetails:
			'From Villa Frangipani, you dont have to go far to find a pampering spa session, or hair spa, to shopping for locally made crockery,  soaps or shampoo that we use in the villa for our guests, to jewellery or clothes boutiques.  Take a day to explore the shops and services nearby. ',
		Info: [
			{
				id: 1,
				name: 'Republic of Soap',
				text: 'standard hotel liquid biodegradable amenities to spa application/bulk and retail, moisturizers, scrubs, sun/after sun care, natural insect repellent, solid shampoo, body oils, candles, room fragrance, reed diffusers, natural deodorant, toothpaste, hand sanitizer and many more.',
				linkName: 'www.republicofsoap.com',
				linkHref: 'http://www.republicofsoap.com/',
			},
			{
				id: 2,
				name: 'Therapy Day Spa',
				text: ' a contemporary sanctuary with an extraordinary subtle Balinese vibe, ushers you to de-stress like never before.  It is a space created for you to escape the roller coasters of life, to unwind the complexities of the mind and to indulge in life’s little pleasures.',
				linkName: 'https://therapy.co.id/',
				linkHref: 'https://therapy.co.id/',
			},
			{
				id: 3,
				name: 'Hair by Therapy',
				text: 'HAIR by therapy is a dedicated to celebrating all hair mindfully. It’s not just a place where clients can get their hair cut, coloured & styled. They source products which are free of sulfates, parabens, palm oil, PPD’s, GMO’s, and artificial colors and fillers and opting for those that are highly composed of plant based, natural ingredients which are cruelty free, and they are an Olaplex certified Hair spa.',
				linkName: 'https://hairbytherapy.com/',
				linkHref: 'https://hairbytherapy.com/',
			},
			{
				id: 4,
				name: 'MilkandRoses Boutique',
				text: 'Some lovely dresses in lovely good quality fabrics, and so Bali vibe for that dinner tomorrow night !',
				linkName: 'https://milkandrosesboutique.com',
				linkHref: 'https://milkandrosesboutique.com/',
			},
			{
				id: 5,
				name: 'leeloobird',
				text: 'Unique jewellery pieces.  LeeLoo Bird carries a French bohemian aesthetic and a strong heritage from Sandrine’s personal journey. Born and raised in Caribbean roots, she studied in France, and lived in Africa. Now Sandrine is based with her family in Indonesia where LeeLoo Bird was born.',
				linkName: 'www.leeloobird.com',
				linkHref: 'http://www.leeloobird.com/',
			},
			{
				id: 6,
				name: 'Canggu & Co',
				text: 'an excellent, comfortable and fast introduction to all the handicrafts on offer in Bali, curated for you and conveniently in one place.',
				linkName: 'cangguco.com',
				linkHref: 'https://cangguco.com/',
			},
			{
				id: 7,
				name: 'Bungalow Living Bali',
				text: 'Cushions with excellent fabrics, lovely home furnishings store, with cafe at the side.   They select and design their own decorative homewares including their own brand of cushions and throws sold by them exclusively.  Well worth a visit.',
				linkName: 'www.bungalowlivingbali.com',
				linkHref: 'http://www.bungalowlivingbali.com/',
			},
			{
				id: 8,
				name: 'Satunama',
				text: 'The retail outlet established by a local Indonesian designer to showcase other Indonesian manufactured homeware.  Manufacturing for Habitat in the UK, or other international brands.',
				linkName: 'https://www.facebook.com/satunamahome/...',
				linkHref: 'https://www.facebook.com/satunamahome/?ref=page_internal',
			},
			{
				id: 9,
				name: '',
				text: 'Canggu shop number',
				linkName: '+62 822-4700-9429',
				linkHref:
					'https://www.google.com/search?q=satunamahome.com+bali&rlz=1C1CHZN_enSG952SG952&ei=JhFaZL_lLdf04-EPpeeHqAc&ved=0ahUKEwi_kNaB9ef-AhVX-jgGHaXzAXUQ4dUDCA8&uact=5&oq=satunamahome.com+bali&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIFCAAQogQyBQgAEKIEMgUIABCiBDIFCAAQogQ6DgguEIAEEMcBEK8BELADOg0ILhANEIAEEMcBEK8BOhsILhANEIAEEMcBEK8BEJcFENwEEN4EEOAEGAE6CAghEKABEMMESgQIQRgBUIIHWN4PYLYYaAFwAHgAgAHLAYgB2QaSAQU0LjMuMZgBAKABAcgBAcABAdoBBggBEAEYFA&sclient=gws-wiz-serp#',
			},
		],
	},
];
export default NeighbourhoodSectionResources;
