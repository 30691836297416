import React from 'react';
import './galleryOne.scss';

export default function GalleryOne({
	img,
	imgClassName,
	textContainerClass,
	title,
	heading = false,
}) {
	return (
		<div
			style={{ background: `url(${img})` }}
			className={`gallery-one ${imgClassName}`}
		>
			<div className={`text-white ${textContainerClass}`}>
				{heading ? (
					<h1 className="title-font">{title}</h1>
				) : (
					<span className="title-font">{title}</span>
				)}
			</div>
		</div>
	);
}
