import { useEffect } from 'react';

export default function useSEOStructuredDataSchema(schema) {
	useEffect(() => {
		if (typeof schema !== 'object') {
			return;
		}

		const scriptElement = document.createElement('script');

		scriptElement.type = 'application/ld+json';

		scriptElement.innerHTML = JSON.stringify(schema);

		document.head.appendChild(scriptElement);

		return () => {
			scriptElement.remove();
		};
	}, [schema]);
}
