import React from 'react';
import FullWidthBannerLighten from '../../../components/banner/FullWidthBannerLighten';
import CopyRight from '../../../components/copyright/CopyRight';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import HeaderSection from './HeaderSection';
import BeachclubsSection from './BeachclubsSection';
import { useSEOTags } from '../../../hooks';
import { TITLES_DESCRIPTIONS } from '../../../constants';

export default function Beachclubs() {
	useSEOTags(
		TITLES_DESCRIPTIONS.beachclubs.title,
		TITLES_DESCRIPTIONS.beachclubs.description,
	);

	return (
		<div className="bg-light-golden overflow-hidden">
			<Navbar />
			<FullWidthBannerLighten img="/assets/img/experience/beachclubs/beach-clubs-in-bali.webp" />
			<HeaderSection />
			<BeachclubsSection />
			<Footer />
			<CopyRight />
		</div>
	);
}
