import React from 'react';

import Navbar from '../../../components/navbar/Navbar';
import Footer from '../../../components/footer/Footer';
import CopyRight from '../../../components/copyright/CopyRight';
import Calendar from '../../../components/calendar/Calendar';
import { useSEOTags } from '../../../hooks';
import { TITLES_DESCRIPTIONS } from '../../../constants';

export default function CheckDates() {
	useSEOTags(
		TITLES_DESCRIPTIONS.bookings.title,
		TITLES_DESCRIPTIONS.bookings.description,
	);

	return (
		<div className="d-flex flex-column">
			<Navbar />
			<Calendar />
			<Footer />
			<CopyRight />
		</div>
	);
}
