import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Redirect({ to }) {
	const navigate = useNavigate();

	useLayoutEffect(() => {
		if (typeof to !== 'string' || to.length <= 0) {
			return;
		}

		navigate(to, {
			replace: true,
		});
	}, [navigate, to]);

	return null;
}
