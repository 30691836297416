const RateResources = [
	{
		id: 1,
		title: 'Low Season',
		info: [
			{ id: 1, text: '4 Bedrooms $465' },
			{ id: 2, text: '3 Bedrooms $425' },
		],
	},
	{
		id: 2,
		title: 'High Season',
		info: [
			{ id: 1, text: '4 Bedrooms $575' },
			{ id: 2, text: '3 Bedrooms $525' },
		],
	},
	{
		id: 3,
		title: 'Peak Season',
		info: [
			{ id: 1, text: '4 Bedrooms $675' },
			{ id: 2, text: '3 Bedrooms $675' },
		],
	},
	{
		id: 4,
		title: 'SEASONS:',
		info: [
			{ id: 1, text: 'Peak: 19 Dec - 10 Jan' },
			{ id: 2, text: 'Low: 10 Jan - 27 Mar' },
			{ id: 3, text: 'High: 27 Mar - 04 Apr' },
			{ id: 4, text: 'Low: 4 Apr - 26 Jun' },
			{ id: 5, text: 'High: 26 Jun- 02 Aug' },
			{ id: 6, text: 'Peak: 02 Aug- 17 Aug' },
			{ id: 7, text: 'High: 17 Aug-01 Sep' },
			{ id: 8, text: 'Low: 01 Sep-19 Dec' },
		],
	},
	{
		id: 5,
		title: 'Rate Inclusions',
		info: [
			{ id: 1, text: 'Welcome drink and canapés' },
			{ id: 2, text: 'Complimentary WiFi' },
			{
				id: 3,
				text: 'Fully staffed villa team with private chef service (excluding grocery cost + 15% handling fee)',
			},
			{ id: 4, text: 'Daily afternoon tea & coffee (4-5pm)' },
			{ id: 5, text: 'Daily housekeeping' },
			{ id: 6, text: 'Concierge service' },
			{ id: 7, text: 'Complimentary one baby cot & baby high chair available' },
		],
	},
];
export default RateResources;
