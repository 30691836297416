import React from 'react';
import './pageTitle.scss';

export default function PageTitle() {
	return (
		<div className="px-5 mx-2 mt-4">
			<h1 className="cu-font-thin light-blue font-size-2-1">
				Comfortable, Bright and Airy. Thoughtful accommodation
			</h1>

			<div className="cu-font-thin light-blue h4 mt-4">Living and Dining</div>
		</div>
	);
}
