const HomeResourses = [
	{
		id: 1,
		img: 'assets/img/home/villa_frangipani_canggu_private_pool.webp',
		imgClassName: 'min-vh-100 row col-12 mx-0',
		textContainerClass:
			'col-lg-5 col-md-6 col-sm-7 col-7 bg-light-blue-08 text-class',
		title: 'Villa Frangipani Canggu, Bali',
	},
	{
		id: 2,
		img: 'assets/img/home/bali-family-holidays-min.webp',
		imgClassName:
			'col-12 col-lg-7 col-md-7 col-sm-12 mt-3 mt-lg-0 mt-md-0 mt-sm-3 gallery-one-img-background d-flex align-items-end p-0 me-1 me-lg-1 me-md-1 me-sm-0',
		textContainerClass: 'mb-5 p-4 h5 bg-light-blue-09',
		title: 'Fun holidays await',
	},
	{
		id: 3,
		img: 'assets/img/home/villa_frangipani_canggu_river_pavilion.webp',
		imgClassName:
			'col-12 col-lg-4 col-md-4 col-sm-12 mt-3 mt-lg-0 mt-md-0 mt-sm-3 gallery-one-img-background d-flex align-items-end p-0 ms-1 ms-ls-1 ms-md-1 ms-sm-0',
		textContainerClass: 'mb-5 p-4 h5 bg-light-blue-09',
		title: 'We take care of everything',
	},
	{
		id: 4,
		img: 'assets/img/home/villa_frangipani_canggu_pool_pavilion.webp',
		imgClassName:
			'col-12 col-lg-3 col-md-3 col-sm-12 mt-3 mt-lg-0 mt-md-0 mt-sm-3 gallery-one-img-background d-flex align-items-end p-0',
		textContainerClass: 'mb-5 p-4 h5 bg-light-blue-09',
		title: 'Relax and do nothing!',
	},
	{
		id: 5,
		img: 'assets/img/home/villa_frangipani_canggu_livingroom.webp',
		imgClassName:
			'col-12 col-lg-4 col-md-4 col-sm-12 mt-3 mt-lg-0 mt-md-0 mt-sm-3 gallery-one-img-background d-flex align-items-end p-0 mx-2',
		textContainerClass: 'mb-5 p-4 h5 bg-light-blue-09',
		title: 'Enjoy every moment',
	},
	{
		id: 6,
		img: 'assets/img/home/villa_frangipani_canggu_ubud_monkey_forrest.webp',
		imgClassName:
			'col-12 col-lg-4 col-md-4 col-sm-12 mt-3 mt-lg-0 mt-md-0 mt-sm-3 gallery-one-img-background d-flex align-items-end p-0',
		textContainerClass: 'mb-5 p-4 h5 bg-light-blue-09',
		title: 'Wonderful Bali',
	},
];
export default HomeResourses;
