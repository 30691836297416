import React from 'react';
import Navbar from '../../../components/navbar/Navbar';
import Footer from '../../../components/footer/Footer';
import CopyRight from '../../../components/copyright/CopyRight';
import FigureSection from './FigureSection';
import { useSEOTags } from '../../../hooks';
import { TITLES_DESCRIPTIONS } from '../../../constants';

export default function Family() {
	useSEOTags(
		TITLES_DESCRIPTIONS.family.title,
		TITLES_DESCRIPTIONS.family.description,
	);

	return (
		<div className="bg-light-golden">
			<Navbar />

			<div className="px-5 px-lg-2 px-md-2 px-sm-5 px-lg-5 px-md-5 px-sm-2 my-5">
				<h1 className="cu-font-thin font-size-2-1 light-blue">
					Family Experience
				</h1>
			</div>

			<FigureSection />

			<Footer />

			<CopyRight />
		</div>
	);
}
