import React from 'react';

export default function HeaderSection() {
	return (
		<div className="d-flex justify-content-center align-items-center">
			<h1 className="cu-font-thin light-blue fs-3 py-2 py-lg-5 py-md-3 py-sm-2">
				Bali’s best beach clubs nearby
			</h1>
		</div>
	);
}
