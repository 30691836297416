import CopyRight from '../../components/copyright/CopyRight';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import { TITLES_DESCRIPTIONS } from '../../constants';
import { useSEOTags } from '../../hooks';
import ContactSection from './ContactSection';
import './contact.scss';

export default function Contact() {
	useSEOTags(
		TITLES_DESCRIPTIONS.contact.title,
		TITLES_DESCRIPTIONS.contact.description,
	);

	return (
		<div className="bg-light-golden">
			<Navbar />
			<ContactSection />
			<Footer />
			<CopyRight />
		</div>
	);
}
