export const TITLES_DESCRIPTIONS = {
	home: {
		title: 'Villa Frangipani Canggu - Luxurious 3-4 Bedroom Villa in Bali',
		description:
			'Experience the charm of Villa Frangipani, a beautifully renovated Balinese villa with 3-4 bedrooms, nestled in the tranquil Pererenan, Canggu. Perfect for families and groups, this villa offers modern amenities and a serene getaway in Bali.',
	},
	rooms: {
		title: 'Luxurious 3 or 4 Bedroom Villa in Canggu - Villa Frangipani',
		description:
			'Discover the elegance of Villa Frangipani in Canggu, featuring a main house with 3 double rooms, a media room, and a fully equipped kitchen. The River Pavilion offers a private 4th bedroom for an exclusive stay.',
	},
	gallery: {
		title:
			'Villa Frangipani Canggu - Photo Gallery of Luxurious Villa and Private Pool',
		description: `Explore our photo gallery showcasing Villa Frangipani's stunning interiors, private pool, and lush gardens. See the beautifully designed main house, river pavilion, media room, and chef's kitchen.`,
	},
	floorplan: {
		title: 'Villa Frangipani Canggu - Detailed Floorplan of Spacious Villa',
		description:
			'View the detailed floorplan of Villa Frangipani Canggu, designed for families. The main pavilion includes 3 large rooms and a media room, while the separate river pavilion offers a private fourth room with an ensuite.',
	},
	dining: {
		title: 'Full-Time Private Chef at Villa Frangipani Canggu',
		description:
			'Enjoy gourmet dining at Villa Frangipani Canggu with our full-time private chef. Indulge in a variety of cuisines including Western, Asian, and Balinese, tailored to your preferences.',
	},
	menu: {
		title: 'Villa Frangipani Canggu - Exclusive Dining Menu',
		description:
			'Explore the exclusive dining menu at Villa Frangipani Canggu. Our chef offers a wide selection of gourmet dishes, including Western, Asian, and Balinese cuisine, crafted to satisfy every palate.',
	},
	family: {
		title: 'Family-Friendly 3 or 4 Bedroom Villa in Canggu - Villa Frangipani',
		description:
			'Perfect for family vacations, Villa Frangipani in Canggu offers spacious 3-4 bedroom accommodations with modern amenities, a private pool, and child-friendly facilities for a memorable stay in Bali.',
	},
	staff: {
		title: 'Dedicated Staff at Villa Frangipani Canggu',
		description:
			'Villa Frangipani Canggu boasts a fully staffed team, including housekeeping, a private chef, and concierge services, ensuring a luxurious and seamless experience during your stay.',
	},
	map: {
		title: 'Villa Frangipani Canggu - Nearby Attractions and Beaches Map',
		description:
			'Explore the prime location of Villa Frangipani Canggu, just 2km from the beach. Discover nearby attractions, restaurants, beach clubs, and shops all within a short drive from the villa.',
	},
	neighbourhood: {
		title: 'Explore Pererenan, Canggu - Shopping, Dining, and Attractions',
		description:
			'Villa Frangipani Canggu is situated in the vibrant Pererenan district. Enjoy a variety of local and international restaurants, excellent shopping, and nearby attractions for a complete Bali experience.',
	},
	beachclubs: {
		title: 'Best Beach Clubs in Bali Near Villa Frangipani Canggu',
		description: `Stay at Villa Frangipani Canggu and enjoy easy access to Bali's top beach clubs. Discover the best spots for sun, sand, and nightlife, many within walking distance from the villa.`,
	},
	attractions: {
		title: 'Top Attractions Near Villa Frangipani Canggu - Explore Bali',
		description: `Discover Bali's top attractions near Villa Frangipani Canggu. From pristine beaches and historic temples to vibrant markets and cultural sites, plan your perfect day trip and explore the island.`,
	},
	reviews: {
		title: 'Guest Reviews of Villa Frangipani Canggu',
		description:
			'Read guest reviews and testimonials about Villa Frangipani Canggu. Learn about their experiences and see why our villa is the perfect choice for your Bali getaway.',
	},
	rates: {
		title: 'Rates & Packages for Villa Frangipani Canggu - Plan Your Stay',
		description:
			'Check out the rates and packages for staying at Villa Frangipani Canggu. Rates vary based on the season and length of stay. Find the best option for your Bali vacation.',
	},
	bookings: {
		title: 'Book Your Stay at Villa Frangipani Canggu - Check Availability',
		description:
			'Plan your perfect getaway at Villa Frangipani Canggu. Check availability and book your stay today for a luxurious experience in Bali.',
	},
	contact: {
		title: 'Contact Villa Frangipani Canggu - Get in Touch',
		description: `Have questions or need assistance? Contact Villa Frangipani Canggu for more information about bookings, services, or any other inquiries. We're here to help you plan your perfect stay in Bali.`,
	},
};
