import { useEffect } from 'react';

export default function useSEOTags(title, description) {
	useEffect(() => {
		const previousTitleValue = document.title;

		if (typeof title === 'string') {
			document.title = title;
		}

		/**
		 * Canonical URL meta
		 */
		const canonicalElement = document.createElement('link');
		canonicalElement.rel = 'canonical';
		canonicalElement.href = `${window.location.origin}${window.location.pathname}`;
		document.head.appendChild(canonicalElement);

		/**
		 * Description meta
		 */
		let descriptionElement = undefined;

		if (typeof description === 'string') {
			const existingDescription = document.head.querySelector(
				'meta[name="description"]',
			);

			/**
			 * Do not overwrite existing meta description
			 */
			if (existingDescription == null) {
				descriptionElement = document.createElement('meta');
				descriptionElement.name = 'description';
				descriptionElement.content = description;
				document.head.appendChild(descriptionElement);
			}
		}

		return () => {
			document.title = previousTitleValue;

			descriptionElement?.remove();

			canonicalElement.remove();
		};
	}, [title, description]);
}
