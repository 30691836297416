import React from 'react';

export default function PageHeader({
	title,
	text,
	titleClassName,
	textClassName,
}) {
	return (
		<div className="m-5">
			<h1 className={`cu-font-thin h2 mb-4 ${titleClassName}`}>{title}</h1>
			<p className={`font-size-1-1 ${textClassName}`}>{text}</p>
		</div>
	);
}
