import React from 'react';
import './Figure.scss';
import CommonHelper from '../../utilities/commonHelper/CommonHelper';

export default function RtlFigure({
	title,
	text,
	img,
	desktopSize,
	mobileSize,
}) {
	return (
		<div className="row my-5">
			{CommonHelper.isMobileDevice() ? (
				<>
					<div
						className="col-12 col-lg-5 col-md-12 col-sm-12 figure-img"
						role="img"
						style={{
							background: `url(${img})`,
							height: `${CommonHelper.isMobileDevice() ? mobileSize : desktopSize}`,
						}}
					/>

					<div className="col-12 col-lg-7 col-md-12 col-sm-12">
						<div className="p-0 p-lg-5 p-md-0 p-sm-0 mt-3">
							<h2 className="cu-font-thin light-blue h4 mb-4">{title}</h2>

							<p className="font-size-1-1">{text}</p>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="col-12 col-lg-7 col-md-12 col-sm-12">
						<div className="p-0 p-lg-5 p-md-0 p-sm-0 mt-3">
							<h2 className="cu-font-thin light-blue h4 mb-4">{title}</h2>
							<p className="font-size-1-1">{text}</p>
						</div>
					</div>
					<div
						className="col-12 col-lg-5 col-md-12 col-sm-12 figure-img"
						role="img"
						style={{
							background: `url(${img})`,
							height: `${CommonHelper.isMobileDevice() ? mobileSize : desktopSize}`,
						}}
					></div>
				</>
			)}
		</div>
	);
}
