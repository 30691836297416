const ReviewsResources = [
	{
		id: 1,
		title1: '"a little piece of heaven"',
		text1:
			'“Villa Frangipani is just a little piece of Heaven. With the excellent staff, who leave no room for wishes. We had a splendid time and will always remember this lovely place and the warm people who make it so hard to leave again.”',
		author1: 'FABIAN AND YULI (PL)',
		title2: '"the new renovations are spectatular"',
		text2:
			'“Dear Villa Frangipani team, our time here was magnificent! The new renovations are spectacular, as is the service from all the staff, from the chef Wayan to house keeping with Ayu & Kadek & the security / gardening team. They all made the time here unforgettable. Love to team Frangipani”',
		author2: 'Chris (AU)',
	},
	{
		id: 2,
		title1: '"wonderful relaxing time"',
		text1:
			'“After 2 weeks travelling through Jawa, we had a wonderful relaxing time here and beautiful villa Frangipani; thanks to the excellent staff!! Nyoman, thank you for your delicious “ETEN” food. We will definitely miss your Nasi Goreng every morning. Kadek and Putu thank you for your hospitality and service during our stay here, especially when you took care of me while I was sick. We will never forget you all.”',
		author1: 'PAUL V. (NL)',
		title2: '"what a privilege to stay"',
		text2:
			'“What a privilege to stay at this wonderful villa and on top of this amazing accommodation and to be taken care off, fed as well and to learn from Putu, Nyoman and Kadek was so wonderful. Thank you for the amazing meals, the daily coconuts with our name and everything else you did behind the scenes to make this such a relaxing holiday in Bali.”',
		author2: 'MELISSA A. (US)',
	},
	{
		id: 3,
		title1: '"fabulous villa in bali"',
		text1:
			'“Thank you so much to Kadek, Nyoman, Putu and Rai for a fabulous Villa in Bali. It was such a luxury to have such gorgeous meals each day and be prepared. Thank you for the cooking tips Nyoman and for looking after these naughty/cheery kids Kadek!! We love Bali. All the best for you all.”',
		author1: 'JAMES S. (AU)',
		title2: '"wonderful experience"',
		text2:
			"“Thank you Nyoman, Kadek and Putu for this wonderful experience at Villa Frangipani. Your smile and service made us stay here comfortable and nice. Our children had a great time swimming in the private pool. This is one of the best vacation we've ever had. Once again Thank you.”",
		author2: 'VIVIEN (HK)',
	},
	{
		id: 4,
		title1: '"warm welcome"',
		text1:
			'“I distinctly remember the warm welcome given to us by the staff of Villa Frangipani upon our arrival. It really stood out & made our day. The staff is extremely friendly & humble and is always available to serve which was a great experience. We got to taste the local Indonesia vegetarian food and everyone loved it. Thanks to staff again!!! Manager Mr. Rai was helpful in chalking out the agenda of our stay & organized taxi & our experience was amazing. Villa gives a very serene feeling and was a pleasant surprise package with very large and inviting interiors like furniture etc. Overall loved it.”',
		author1: 'ASHISH J. (KW)',
		title2: '"midnight arrival with smiles"',
		text2:
			'“Thank You so much for hosting us in your beautiful Villa. It was a once in a lifetime experience. Despite our midnight arrival you were all still waiting with smiles and warm food. We had a wonderful time in Bali. Mostly thanks to the friendly staff of villa frangipani. We all hope to return again soon.“',
		author2: 'ADAM A. (FR)',
	},
	{
		id: 5,
		title1: "if you don't book this villa in Canggu , you are mad!",
		text1:
			"“We went here for a friends birthday and it did not disappoint! Arrival with airport transfer was smooth and easy with no hassle. Staff were incredibly friendly and very willing to accommodate We were a bit rowdy at times but they were very understanding. Also felt very safe as there are security guards who work 24/7. The food & the villa was excellent, such a serene and peaceful place to relax. Villa would be perfect for anyone looking to relax and have a great holiday whether that be with family, friends or a couple. Additionally, the villa is only a short drive from the world-famous Echo beach, you can't really fault this villa! We also order in house massages everyday, which just made the whole experience that much better! If you don't book this villa in Canggu you are mad!”",
		author1: 'TOSCA T. (SG)',
		title2: '"wonderful staff"',
		text2:
			"“We absolutely love Villa Frangipani. It's our home in Bali. Beautiful, peaceful & wonderful staff, so helpful and always with a lovely smile. We had a dinner party last night with 14 guests and their children. Made, Ayu, and Kadek organized a delicious feast, and it was a very special evening. We don't want leave and will come back very soon.”",
		author2: 'KIM M. (SG)',
	},
	{
		id: 6,
		title1: '"little piece of paradise"',
		text1:
			"“Made, Kadek, Ayu & Nengah.. Thank you for sharing your little place in paradise with our family. Made, skill and care in preparing our wonderful meals made every mealtime a great experience. The flavours and variety in which you could prepare our meals are truly amazing. Kadek & Ayu, thank you for making us feel so welcome. There was nothing that was too much trouble for you and we felt very very pampered from the moment we woke up until after dinner each night. We felt safe and a big thank-you to our gardener Nengah and all the security team who kept the grounds beautiful, tidy and looking like heaven on earth each day. We can't wait to be back and we will recommend to our family and friends. all our love..”",
		author1: 'CHRIS C. (AU)',
		title2: '"nothing was too much trouble"',
		text2:
			'“Thank you for such a wonderful stay at Villa Frangipani. We were looked after so well and will come to visit the beautiful villa, staff and amazing food we have tasted here. Nothing was too much trouble and we felt very spoilt. The villa is special as it is Balinese in its look, feel and we felt was at home here. Thank you again.”',
		author2: 'KIRSTEN L. (DK)',
	},
	{
		id: 7,
		title1: '"peaceful surroundings"',
		text1:
			'“Beautiful villa, peaceful surrounding & amazing, caring staff. Thank you Made for your delicious cooking and Kadek & Ayu for all your care. We loved it and we love to come back. Thank you & lots of love!”',
		author1: 'NINELL S. (HK)',
		title2: '"lovely stay"',
		text2:
			"“Dear Kadek, Ayu and Made, Jati What a lovely stay at the Villa Frangipani!!! Without all of you it won't be such an amazing experience. Thank you very much for warm welcome, your kindness, your smile. See you soon.”",
		author2: 'HENRI R. (FR)',
	},
	{
		id: 8,
		title1: '"a taste of paradise"',
		text1:
			'“As we arrived at the villa we were greeted outside by the staff of our villa and at that point our holiday in paradise began. The villa Frangipani itself is beautifully appointed with genuine Balinese furnishings, very spacious and private. The staff were more than attentive, and nothing was too much trouble. I wanted to take them home with me. We chose our menus each morning and our meals were beautifully presented at our dining table situated undercover on the verandah by the swimming pool which was surrounded by frangipani trees. Thank you, Villa Frangipani, for a most memorable and relaxing holiday I would love to return and can highly recommend the experience.”',
		author1: 'SHEELAGH C. (AU)',
		title2: '"very peaceful and homely villa"',
		text2:
			'“A very peaceful and homely villa. The staff are excellent, and the cook made the best Indonesian food available - we ate at restaurants during the day, but nothing came closer than the food he cooked at night. In fact, we had dinner parties for 10+ people since we had a lot of family at the same time for a wedding. The mornings were very peaceful with breakfast by the pool and a nice dip in the pool after. The property has a separate cottage for a couple that may want to be a little away from the main villa. We had a 16-month toddler who they cooked for as well. All in all, it was a fantastic villa and a great stay - would do it again in a heartbeat”',
		author2: 'MAULIN C. (US)',
	},
	{
		id: 9,
		title1: '"wow what a fantastic private villas in paradise"',
		text1:
			'“Villa Frangipani blew us away on arrival. So private, great huge bedrooms with aircon, spacious villa opening out to a great under cover entertainment area and massive pool which looks over a beautifully kept garden and another private villa down back. The staff were lovely and friendly and helpful and the in-house massages fantastic. We had a great time and enjoyed the 10-minute taxi rides to Canggu - where there are amazing sunsets ,awesome cafes and restaurants and bars. We left super relaxed , much better at chess and a few good books reads.”',
		author1: 'MATTHEW W. – AUSTRALIA (AU)',
		title2: '"A total immersion of Balinese beauty"',
		text2:
			'My five day stay was pure luxury - from the spacious rooms to the serene ambience of the surrounding gardens. The staff were warm and courteous, showing love and care for everything they did, from the meals prepared to the timely coffees and cocktails, just when they are needed. It’s clear the owners have take great lengths to ensure the renovations and staff are just perfect!',
		author2: 'Shirley (SG)',
	},
];
export default ReviewsResources;
