const footerResourses = [
	{
		id: 1,
		img: '/assets/img/experience/beachclubs/LB-Xmas-Market-YP211223127-scaled.webp',
		title: 'La Brisa Beach Club',
		info1:
			'It is a beach club in Canggu with a boho-chic atmosphere, sustainable design, and oceanfront views.',
		info2: '3 km from Villa Frangipani Canggu (or access via the beach)',
		button: 'MORE INFO',
		buttonHref: 'https://labrisa-bali.com/',
	},
	{
		id: 2,
		img: '/assets/img/experience/beachclubs/df6102339bf1e735c977b13ab5ea812d.webp',
		title: 'COMO Beach Club',
		info1:
			'Is a beachfront restaurant, bar, and lounge located at COMO Uma Canggu in Canggu, Bali.',
		info2: '3 km from Villa Frangipani Canggu (or access from beach)',
		button: 'MORE INFO',
		buttonHref:
			'https://www.comohotels.com/en/umacanggu/dining/como-beach-club',
	},
	{
		id: 3,
		img: '/assets/img/experience/beachclubs/old-mans.webp',
		title: "Old Man's Beach Club",
		info1:
			'A popular beachfront bar in Canggu with live music and DJ performances.',
		info2: '5 km from Villa Frangipani Canggu',
		button: 'MORE INFO',
		buttonHref: 'https://oldmans.net/',
	},
	{
		id: 4,
		img: '/assets/img/experience/beachclubs/da0335_2407a950058741a58401830d3f49ccd1f000.webp',
		title: 'The Lawn Beach Club',
		info1: 'A beach club in Canggu with stunning sunset views and live music.',
		info2: '5 km from Villa Frangipani. Canggu',
		button: 'MORE INFO',
		buttonHref: 'https://www.thelawncanggu.com/',
	},
	{
		id: 5,
		img: '/assets/img/experience/beachclubs/st-tropez_wine-tasting-61.webp',
		title: 'Finns Beach Club',
		info1:
			'A beachfront venue in Canggu with several bars, restaurants, a large pool, and DJ performances.',
		info2: '7 km from Villa Frangipani Canggu',
		button: 'MORE INFO',
		buttonHref: 'https://www.finnsbeachclub.com/',
	},
	{
		id: 6,
		img: '/assets/img/experience/beachclubs/website-compressed-.webp',
		title: 'Cafe del Mar Beach Club',
		info1:
			'A beachfront setting offering poolside cabanas, live music, and a restaurant serving Mediterranean cuisine.',
		info2: '7 km from Villa Frangipani. Canggu',
		button: 'MORE INFO',
		buttonHref: 'https://cafedelmarbali.co.id/',
	},
	{
		id: 7,
		img: '/assets/img/experience/beachclubs/desa-potato-head-seminyak-beach-club-03.webp',
		title: 'Potato Head Beach Club',
		info1:
			'A luxury beach club in Seminyak with several bars, restaurants, and a pool.',
		info2: '10 km from Villa Frangipani Canggu',
		button: 'MORE INFO',
		buttonHref: 'https://seminyak.potatohead.co/feast/beach-club/',
	},
	{
		id: 8,
		img: '/assets/img/experience/beachclubs/pic_1_c52e74a9be.webp',
		title: 'Mrs. Sippy Beach Club',
		info1:
			'A pool club in Seminyak with a swim-up bar, regular DJ performances, and a diving platform.',
		info2: '10 km from Villa Frangipani Canggu',
		button: 'MORE INFO',
		buttonHref: 'https://www.mrssippybali.com/',
	},
	{
		id: 9,
		img: '/assets/img/experience/beachclubs/dpswh-woobar-7003-hor-wide.webp',
		title: 'Woobar Beach Club',
		info1:
			'A bar and lounge in Seminyak with a beachfront location and live music performances.',
		info2: '10 km from Villa Frangipani. Canggu',
		button: 'MORE INFO',
		buttonHref:
			'https://www.marriott.com/en-us/hotels/dpswh-w-bali-seminyak/dining/',
	},
	{
		id: 10,
		img: '/assets/img/experience/beachclubs/KDT-home-D.webp',
		title: 'Ku De Ta Beach Club',
		info1:
			'A beachfront bar and restaurant in Seminyak with a lively atmosphere and great cocktails.',
		info2: '11 km from Villa Frangipani. Canggu',
		button: 'MORE INFO',
		buttonHref: 'https://www.kudeta.com/',
	},
];
export default footerResourses;
