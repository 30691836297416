import React from 'react';
import CopyRight from '../../../components/copyright/CopyRight';
import Description from '../../../components/description/Description';
import Footer from '../../../components/footer/Footer';
import Navbar from '../../../components/navbar/Navbar';
import PageTitle from './PageTitle';
import SliderSection from './SliderSection';

import { useSEOTags } from '../../../hooks';
import { TITLES_DESCRIPTIONS } from '../../../constants';

export default function RoomsAndMore() {
	useSEOTags(
		TITLES_DESCRIPTIONS.rooms.title,
		TITLES_DESCRIPTIONS.rooms.description,
	);

	return (
		<div className="bg-light-golden">
			<Navbar />

			<PageTitle />

			<Description />

			<SliderSection />

			<Footer />

			<CopyRight />
		</div>
	);
}
