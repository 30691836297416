import React from 'react';

export default function Title() {
	return (
		<div className="px-2 px-lg-5 px-md-5 px-sm-2 mt-4 ">
			<h1 className="cu-font-thin font-size-2-1 text-black">
				Villa Frangipani Canggu Floor Plan
			</h1>

			<div className="font-size-1-1 mt-5">
				Villa Frangipani Canggu sits on the river’s edge (well fenced) providing
				a peaceful backdrop of running water. Depending on the time of year. The
				1500sqm property is well appointed with beautiful gardens.
			</div>
		</div>
	);
}
