import { useSEOStructuredDataSchema } from './hooks';
import { STRUCTURED_DATA } from './constants';
import { Router } from './router';

// function App() {
// 	return (
// 		<div className="App">
// 			<Home />
// 		</div>
// 	);
// }

export default function App() {
	useSEOStructuredDataSchema(STRUCTURED_DATA);

	return <Router />;
}
