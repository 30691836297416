const AboutResources = [
	{
		id: 1,
		img: 'assets/img/home/fully_staffed_withchef_villa_frangipani_canggu.webp',
		title: 'Welcome to Bali, and to our home, Villa Frangipani Canggu',
		about:
			'Welcome to Villa Frangipani Canggu, your private villa in Canggu, Bali. This exquisite 4 bedroom villa, including a 3 bedroom main villa and a separate river pavilion, is nestled on the edge of the serene Pangi river, in the tranquil rural village of Pererenan. Surrounded by towering trees and lush gardens, our villa offers a peaceful retreat while being conveniently located near top-notch restaurants and prime surfing beaches. Villa Frangipani Canggu, a villa with a private pool in Canggu, was designed and constructed by the esteemed group behind the Four Seasons Bali. Following an extensive renovation in 2023, it now offers guests the unique opportunity to experience the charming design of a traditional open-plan Balinese home, complete with all the luxuries and amenities of a modern villa. With spacious living and dining areas opening onto a 15-meter salt chlorinated infinity edge pool, and a villa with a chef in Canggu, Bali, we offer unmatched hospitality and a home away from home experience.',
	},
];
export default AboutResources;
