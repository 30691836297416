const navbarResources = [
	{
		id: 1,
		className: 'ps-1',
		linkClassName: 'text-light h6',
		name: 'Home',
		imgClassName: 'd-none',
		route: '/',
		childs: [],
	},
	{
		id: 2,
		className: 'ps-1 mt-4 pt-2',
		linkClassName: 'text-light h6 text-decoration-none',
		name: 'The Villa',
		imgClassName: 'ms-1',
		route: '/the-villa',
		childs: [
			{ id: 1, submenu: 'Rooms and more', route: '/the-villa/rooms-and-more' },
			{ id: 2, submenu: 'Gallery', route: '/the-villa/gallery' },
			{ id: 3, submenu: 'Floor Plan', route: '/the-villa/floorplan' },
		],
	},
	{
		id: 3,
		className: 'ps-1 mt-4 pt-2',
		linkClassName: 'text-light h6 text-decoration-none',
		name: 'Experience',
		imgClassName: 'ms-1',
		route: '/experience',
		childs: [
			{ id: 1, submenu: 'Dining', route: '/experience/dining' },
			{ id: 2, submenu: 'Our Menu', route: '/experience/menu' },
			{ id: 3, submenu: 'Family', route: '/experience/family' },
			{ id: 4, submenu: 'Staff', route: '/experience/staff' },
		],
	},
	{
		id: 4,
		className: 'ps-1 mt-4 pt-2',
		linkClassName: 'text-light h6 text-decoration-none',
		name: 'Location',
		imgClassName: 'ms-1',
		route: '/location',
		childs: [
			{ id: 1, submenu: 'Map', route: '/location/map' },
			{ id: 2, submenu: 'Neighbourhood', route: '/location/neighbourhood' },
			{ id: 3, submenu: 'Beach clubs', route: '/location/beachclubs' },
			{ id: 4, submenu: 'Attractions', route: '/location/attractions' },
		],
	},
	{
		id: 5,
		className: 'ps-1 mt-4 pt-2',
		linkClassName: 'text-light h6 text-decoration-none',
		name: 'Availability',
		imgClassName: 'ms-1',
		route: '/availability',
		childs: [
			{ id: 1, submenu: 'Reviews', route: '/availability/reviews' },
			{ id: 2, submenu: 'Rates', route: '/availability/rates' },
			{ id: 3, submenu: 'Check Dates', route: '/availability/check-dates' },
		],
	},
	{
		id: 6,
		className: 'ps-1 mt-4 pt-2',
		linkClassName: 'text-light h6 text-decoration-none',
		name: 'Contact',
		imgClassName: 'd-none',
		route: '/contact',
		childs: [],
	},
];

export default navbarResources;
