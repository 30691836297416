const GalleryResources = [
	{
		id: 1,
		img: '/assets/img/theVilla/gallery/1.webp',
		title: 'Balinese Entrance Gate',
	},
	{
		id: 2,
		img: '/assets/img/theVilla/gallery/2.webp',
		title: 'Paliman courtyard',
	},
	{
		id: 3,
		img: '/assets/img/theVilla/gallery/3.webp',
		title: 'Villa Frangipani entrance at night',
	},
	{
		id: 4,
		img: '/assets/img/theVilla/gallery/4.webp',
		title: 'An inviting welcome',
	},
	{
		id: 5,
		img: '/assets/img/theVilla/gallery/5.webp',
		title: 'Indoor Living Room',
	},
	{
		id: 6,
		img: '/assets/img/theVilla/gallery/6.webp',
		title: 'Indoor Living - large sofa beds',
	},
	{
		id: 7,
		img: '/assets/img/theVilla/gallery/7.webp',
		title: 'Relax on a daybed',
	},
	{
		id: 8,
		img: '/assets/img/theVilla/gallery/8.webp',
		title: 'Indoor living opens to outdoor Living',
	},
	{
		id: 9,
		img: '/assets/img/theVilla/gallery/9.webp',
		title: 'A great area to chill',
	},
	{
		id: 10,
		img: '/assets/img/theVilla/gallery/10.webp',
		title: 'Not a care in the world',
	},
	{
		id: 11,
		img: '/assets/img/theVilla/gallery/11.webp',
		title: 'Watch the kids or the world go by',
	},
	{
		id: 12,
		img: '/assets/img/theVilla/gallery/12.webp',
		title: 'Breakfast or working table',
	},
	{
		id: 13,
		img: '/assets/img/theVilla/gallery/13.webp',
		title: 'Dining table seats 10 to 12',
	},
	{
		id: 14,
		img: '/assets/img/theVilla/gallery/14.webp',
		title: 'Relax by the pool at night',
	},
	{
		id: 15,
		img: '/assets/img/theVilla/gallery/15.webp',
		title: 'Outdoor seating by the pool',
	},
	{
		id: 16,
		img: '/assets/img/theVilla/gallery/16.webp',
		title: 'Outdoor Living , Dining Pavilion',
	},
	{
		id: 17,
		img: '/assets/img/theVilla/gallery/17.webp',
		title: 'A lovely outlook',
	},
	{
		id: 18,
		img: '/assets/img/theVilla/gallery/18.webp',
		title: 'Media / massage room',
	},
	{
		id: 19,
		img: '/assets/img/theVilla/gallery/19.webp',
		title: 'Fully equipped chefs kitchen',
	},
	{
		id: 20,
		img: '/assets/img/theVilla/gallery/20.webp',
		title: 'Master bedroom pool outlook',
	},
	{
		id: 21,
		img: '/assets/img/theVilla/gallery/21.webp',
		title: 'Master bedroom desk',
	},
	{
		id: 22,
		img: '/assets/img/theVilla/gallery/22.webp',
		title: 'Master bedroom',
	},
	{
		id: 23,
		img: '/assets/img/theVilla/gallery/23.webp',
		title: 'Master Bedroom Ensuite',
	},
	{
		id: 24,
		img: '/assets/img/theVilla/gallery/24.webp',
		title: 'Junior Master (super singles)',
	},
	{
		id: 25,
		img: '/assets/img/theVilla/gallery/25.webp',
		title: 'Junior master ensuite',
	},
	{
		id: 26,
		img: '/assets/img/theVilla/gallery/26.webp',
		title: 'Garden room (beds can be joined)',
	},
	{
		id: 27,
		img: '/assets/img/theVilla/gallery/27.webp',
		title: 'Garden room ensuite',
	},
	{
		id: 28,
		img: '/assets/img/theVilla/gallery/28.webp',
		title: 'Chill in the shade by the pool',
	},
	{
		id: 29,
		img: '/assets/img/theVilla/gallery/29.webp',
		title: 'Sandstone carvings',
	},
	{
		id: 30,
		img: '/assets/img/theVilla/gallery/30.webp',
		title: 'A great size pool for everyone',
	},
	{
		id: 31,
		img: '/assets/img/theVilla/gallery/31.webp',
		title: 'Privacy with mature gardens',
	},
	{
		id: 32,
		img: '/assets/img/theVilla/gallery/32.webp',
		title: 'Villa Frangipani Canggu at Dusk',
	},
	{
		id: 33,
		img: '/assets/img/theVilla/gallery/33.webp',
		title: 'Path to the River Pavilion',
	},
	{
		id: 34,
		img: '/assets/img/theVilla/gallery/34.webp',
		title: 'River Pavilion Chairs',
	},
	{
		id: 35,
		img: '/assets/img/theVilla/gallery/35.webp',
		title: 'Relaxing Planters Chairs',
	},
	{
		id: 36,
		img: '/assets/img/theVilla/gallery/36.webp',
		title: 'River Pavilion Outlook',
	},
	{
		id: 37,
		img: '/assets/img/theVilla/gallery/37.webp',
		title: 'Fully Staffed',
	},
	{
		id: 38,
		img: '/assets/img/theVilla/gallery/38.webp',
		title: 'River Pavilion Outdoor Bathroom',
	},
	{
		id: 39,
		img: '/assets/img/theVilla/gallery/39.webp',
		title: 'River Pavilion Double Vanity',
	},
	{
		id: 40,
		img: '/assets/img/theVilla/gallery/40.webp',
		title: 'River Pavilion Path at Night',
	},
	{
		id: 41,
		img: '/assets/img/theVilla/gallery/41.webp',
		title: 'Lumbung at night',
	},
	{
		id: 42,
		img: '/assets/img/theVilla/gallery/42.webp',
		title: 'Lower Lumbung - massage bed',
	},
	{
		id: 43,
		img: '/assets/img/theVilla/gallery/43.webp',
		title: 'Upper Lumbung - Childrens "treehouse"',
	},
	{
		id: 44,
		img: '/assets/img/theVilla/gallery/44.webp',
		title: 'Villa Frangipani Canggu Staff',
	},
	{
		id: 45,
		img: '/assets/img/theVilla/gallery/45.webp',
		title: 'Villa Frangipani Canggu ariel view',
	},
];
export default GalleryResources;
