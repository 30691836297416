const BannerResources = [
	{
		id: 1,
		bannerImg: 'assets/img/home/villa_frangipani_canggu_paddy_fields.webp',
	},
	{
		id: 2,
		bannerImg: 'assets/img/home/villa_frangipani_canggu_pererenan_beach.webp',
	},
];
export default BannerResources;
