const footerResourses = [
	{
		id: 1,
		name: 'Home',
		Route: '/',
	},
	{
		id: 2,
		name: 'Location',
		Route: '/location/map',
	},
	{
		id: 3,
		name: 'The Villa',
		Route: '/the-villa/rooms-and-more',
	},
	{
		id: 4,
		name: 'Availability',
		Route: '/availability',
	},
	{
		id: 5,
		name: 'Gallery',
		Route: '/the-villa/gallery',
	},
	{
		id: 6,
		name: 'Reviews',
		Route: '/availability/reviews',
	},
	{
		id: 7,
		name: 'Experience',
		Route: '/experience',
	},
	{
		id: 8,
		name: 'Contact',
		Route: '/contact',
	},
];
export default footerResourses;
