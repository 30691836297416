import React from 'react';
import Navbar from '../../../components/navbar/Navbar';
import Footer from '../../../components/footer/Footer';
import CopyRight from '../../../components/copyright/CopyRight';
import Title from './Title';
import MapImg from './MapImg';
import { useSEOTags } from '../../../hooks';
import { TITLES_DESCRIPTIONS } from '../../../constants';

export default function FloorPlan() {
	useSEOTags(
		TITLES_DESCRIPTIONS.floorplan.title,
		TITLES_DESCRIPTIONS.floorplan.description,
	);

	return (
		<div className="bg-light-golden">
			<Navbar />
			<Title />
			<MapImg />
			<Footer />
			<CopyRight />
		</div>
	);
}
