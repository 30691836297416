import React from 'react';
import Navbar from '../../../components/navbar/Navbar';
import Footer from '../../../components/footer/Footer';
import CopyRight from '../../../components/copyright/CopyRight';
import Header from './Header';
import ReviewsSection from './ReviewsSection';
import { useSEOTags } from '../../../hooks';
import { TITLES_DESCRIPTIONS } from '../../../constants';

export default function Reviews() {
	useSEOTags(
		TITLES_DESCRIPTIONS.reviews.title,
		TITLES_DESCRIPTIONS.reviews.description,
	);

	return (
		<div className="bg-light-golden">
			<Navbar />
			<Header />
			<ReviewsSection />
			<Footer />
			<CopyRight />
		</div>
	);
}
