import React from 'react';
import './BeachclubsCard.scss';

export default function BeachclubsCard({
	key,
	containerClassName,
	img,
	title,
	info1,
	info2,
	button,
	buttonHref,
}) {
	return (
		<div key={key} className={`light-gray-bg my-5 mx-2 ${containerClassName}`}>
			<div className="py-4 py-lg-5 py-md-5 py-sm-5 px-3 px-lg-4 px-md-4 px-sm-3 d-flex justify-content-center align-items-center flex-column">
				<div
					className="w-100 beachclubs-card-img"
					role="img"
					style={{ background: `url(${img})` }}
				></div>

				<h2 className="light-blue fw-bold fs-3 mt-4 mb-3">{title}</h2>

				<p className="text-center fs-6 px-3 px-lg-5 px-md-5 px-sm-3">{info1}</p>

				<p className="text-center fs-6 px-3 px-lg-5 px-md-5 px-sm-3 my-4">
					{info2}
				</p>

				<button
					type="button"
					className="btn bg-light-blue font-size-1 text-white py-3 px-5 my-3"
				>
					<a
						className="text-decoration-none text-white"
						target="_blank"
						href={buttonHref}
						rel="noreferrer"
					>
						{button}
					</a>
				</button>
			</div>
		</div>
	);
}
