import { TITLES_DESCRIPTIONS } from './titles-description';

import { resolvePathOrigin } from '../utilities';

const address = {
	addressCountry: 'ID',
	addressLocality: 'Kabupaten Badung',
	addressRegion: 'Bali',
	postalCode: '80351',
	streetAddress: 'Jl. Dalem Gede No.23, Pererenan, Kec. Mengwi',
};

const identifier = Object.entries(address)
	.sort(([a], [b]) => a.localeCompare(b))
	.reduce(
		(accumulator, [, value]) => accumulator + '-' + value.replaceAll(' ', '-'),
		'villa-frangipani-canggu',
	)
	.toLowerCase();

const bed = [
	/**
	 * Master Bedroom
	 */
	{
		'@type': 'BedDetails',
		numberOfBeds: 1,
		typeOfBed: 'King',
	},
	/**
	 * River Pavilion
	 */
	{
		'@type': 'BedDetails',
		numberOfBeds: 1,
		typeOfBed: 'Queen',
	},
	/**
	 * Junior Bedroom
	 * Garden Bedroom
	 */
	{
		'@type': 'BedDetails',
		numberOfBeds: 4,
		typeOfBed: 'Single',
	},
];

/**
 * @see {@link https://schema.org/VacationRental}
 */
export const STRUCTURED_DATA = {
	'@context': 'https://schema.org',
	'@type': 'VacationRental',
	/**
	 * Use address as identifier
	 */
	identifier,
	additionalType: 'Villa',
	name: 'Villa Frangipani Canggu',
	description: TITLES_DESCRIPTIONS.home.description,
	latitude: '-8.6392942',
	longitude: '115.1346332',
	address,
	priceRange: '$425-$675',
	telephone: '+62361754344',
	containsPlace: {
		'@type': 'Accommodation',
		additionalType: 'EntirePlace',
		occupancy: {
			'@type': 'QuantitativeValue',
			value: 10,
		},
		bed,
		amenityFeature: [
			{
				'@type': 'LocationFeatureSpecification',
				name: 'ac',
				value: true,
			},
			{
				'@type': 'LocationFeatureSpecification',
				name: 'internetType',
				value: 'Free',
			},
			{
				'@type': 'LocationFeatureSpecification',
				name: 'kitchen',
				value: true,
			},
			{
				'@type': 'LocationFeatureSpecification',
				name: 'outdoorGrill',
				value: true,
			},
			{
				'@type': 'LocationFeatureSpecification',
				name: 'ovenStove',
				value: true,
			},
			{
				'@type': 'LocationFeatureSpecification',
				name: 'pool',
				value: true,
			},
			{
				'@type': 'LocationFeatureSpecification',
				name: 'poolType',
				value: 'Outdoor',
			},
			{
				'@type': 'LocationFeatureSpecification',
				name: 'tv',
				value: true,
			},
			{
				'@type': 'LocationFeatureSpecification',
				name: 'wifi',
				value: true,
			},
		],
		floorSize: {
			'@type': 'QuantitativeValue',
			value: 1500,
			unitCode: 'SQM',
		},
		numberOfBathroomsTotal: 4,
		numberOfBedrooms: bed.reduce(
			(accumulator, currentValue) => accumulator + currentValue.numberOfBeds,
			0,
		),
		numberOfRooms: 4,
	},
	image: [
		/**
		 * Master Bedroom
		 */
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/1.webp'),
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/1-2.webp'),
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/1-3.webp'),

		/**
		 * Media / Spa Room
		 */
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/5.webp'),

		/**
		 * Kitchen
		 */
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/6.webp'),

		/**
		 * Junior Bedroom
		 */
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/2.webp'),
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/2-2.webp'),

		/**
		 * Garden Bedroom
		 */
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/3.webp'),
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/3-2.webp'),

		/**
		 * River Pavilion
		 */
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/4.webp'),
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/4-2.webp'),
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/4-3.webp'),
		resolvePathOrigin('/assets/img/theVilla/roomsAndMore/4-4.webp'),
	],
	sameAs: [
		'https://www.facebook.com/VillaFrangipaniWelcome/',
		'https://www.instagram.com/villa_frangipani/',
	],
};
